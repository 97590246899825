{
  "ds": "rgb(254, 236, 108)",
  "dsg": "rgb(242, 214, 97)",
  "dg": "rgb(228, 195, 49)",
  "do": "rgb(169, 145, 49)",
  "s": "rgb(255, 204, 36)",
  "sg": "rgb(239, 161, 32)",
  "g": "rgb(41, 127, 86)",
  "o": "rgb(59, 155, 87)",
  "os": "rgb(254, 197, 113)",
  "osg": "rgb(239, 163, 94)",
  "ov": "rgb(198, 220, 110)",
  "ovg*": "rgb(110, 155, 64)",
  "ovo*": "rgb(146, 201, 85)",
  "og*": "rgb(55, 103, 66)",
  "oo*": "rgb(85, 186, 96)",
  "v": "rgb(151, 79, 69)",
  "vg*": "rgb(141, 47, 47)",
  "vo*": "rgb(225, 88, 77)",
  "g*": "rgb(170, 170, 95)",
  "o*": "rgb(236, 222, 158)",
  "b": "rgb(71, 162, 158)",
  "k": "rgb(166, 219, 217)",
  "kb": "rgb(117, 198, 198)",
  "kv": "rgb(205, 167, 111)",
  "kvo*": "rgb(134, 204, 167)",
  "kg*": "rgb(80, 175, 120)",
  "v*": "rgb(117, 67, 60)",
  "DB1": "rgb(205, 128, 146)",
  "DB2": "rgb(172, 79, 98)",
  "EE1": "rgb(133, 140, 55)",
  "EE2": "rgb(165, 179, 71)",
  "DN": "rgb(244, 121, 79)",
  "PE": "rgb(246, 155, 150)",
  "UR": "rgb(111, 79, 160)",
  "UAP": "rgb(80, 66, 141)",
  "ST": "rgb(172, 154, 202)",
  "SY": "rgb(223, 175, 209)",
  "YS": "rgb(64, 129, 130)",
  "WA": "rgb(137, 124, 186)",
  "MO": "rgb(46, 80, 99)",
  "KI": "rgb(135, 115, 81)",
  "BR": "rgb(232, 110, 67)",
  "RTD": "rgb(167, 164, 138)",
  "MT": "rgb(186, 211, 150)",
  "GU": "rgb(86, 132, 97)",
  "VA": "rgb(75, 96, 69)",
  "VKZ": "rgb(94, 201, 229)",
  "MR": "rgb(155, 82, 160)",
  "EP": "rgb(235, 63, 36)",
  "SB1": "rgb(230,255,191)",
  "SB2": "rgb(191,255,166)",
  "SB3": "rgb(255,191,0)",
  "SB4": "rgb(255,230,217)",
  "U1": "rgb(153,255,128)",
  "U2": "rgb(204,204,102)",
  "NA1": "rgb(255,255,204)",
  "NA2": "rgb(230,230,74)",
  "NA3": "rgb(145,171,0)",
  "NA4": "rgb(217,191,125)",
  "NA5": "rgb(230,242,125)",
  "NA6": "rgb(230,230,77)",
  "NA7": "rgb(217,191,128)",
  "NA8": "rgb(179,153,51)",
  "NA9": "rgb(204,255,128)",
  "NA10": "rgb(102,179,51)",
  "NA11": "rgb(204,230,102)",
  "NA12": "rgb(179,217,51)",
  "NA13": "rgb(179,242,77)",
  "NA14": "rgb(230,242,128)",
  "NA15": "rgb(153,179,89)",
  "NA16": "rgb(89,153,77)",
  "NA17": "rgb(191,128,89)",
  "NI1": "rgb(153,115,64)",
  "NI2": "rgb(230,204,166)",
  "NI3": "rgb(230,145,84)",
  "NI4": "rgb(128,77,77)",
  "NI5": "rgb(230,255,242)",
  "EC1": "rgb(179,255,217)",
  "EC2": "rgb(153,255,217)",
  "EC3": "rgb(166,230,191)",
  "EC4": "rgb(153,204,179)",
  "EC5": "rgb(140,179,153)",
  "EC6": "rgb(128,153,128)",
  "EC7": "rgb(255,255,140)",
  "BX1": "rgb(204,191,77)",
  "BX2": "rgb(153,153,77)",
  "BX3": "rgb(242,255,140)",
  "BX4": "rgb(255,217,102)",
  "BX5": "rgb(255,191,38)",
  "BX6": "rgb(230,217,128)",
  "BX7": "rgb(230,204,51)",
  "BX8": "rgb(204,102,25)",
  "BX9": "rgb(217,230,255)",
  "BE1": "rgb(191,217,255)",
  "BE2": "rgb(140,191,255)",
  "BE3": "rgb(115,166,255)",
  "BE4": "rgb(89,140,255)",
  "BE5": "rgb(204,217,230)",
  "KR1": "rgb(166,191,230)",
  "KR2": "rgb(255,204,204)",
  "DR1": "rgb(255,140,204)",
  "DR2": "rgb(255,179,204)",
  "DR3": "rgb(255,77,204)",
  "DR4": "rgb(191,153,179)",
  "DR5": "rgb(230,153,25)",
  "DN1": "rgb(0,255,204)",
  "UR1": "rgb(230,242,204)",
  "PE1": "rgb(166,179,217)",
  "ST1": "rgb(77,77,255)",
  "SY1": "rgb(255,102,153)",
  "WA1": "rgb(179,217,255)",
  "UAP1": "rgb(204,166,255)",
  "G1": "rgb(128,89,128)",
  "G2": "rgb(255,255,191)",
  "KI1": "rgb(255,242,128)",
  "KI2": "rgb(255,242,64)",
  "OO1": "rgb(242,217,0)",
  "BR1": "rgb(204,255,0)",
  "RU1": "rgb(191,242,0)",
  "RU2": "rgb(102,204,0)",
  "TO1": "rgb(3,128,128)",
  "DO1": "rgb(166,230,153)",
  "MT1": "rgb(128,230,128)",
  "GU1": "rgb(64,230,77)",
  "VA1": "rgb(0,217,0)",
  "AK1": "rgb(0,191,0)",
  "VZK1": "rgb(128,102,179)",
  "MR1": "rgb(128,102,179)",
  "EP1": "rgb(130,106,12)7"
}
